<template>
  <router-view class="main-content" />
</template>

<script>
  import { defineComponent } from 'vue';
  export default defineComponent({
    setup() {
      return {};
    },
  });
</script>

<style lang="less"></style>
