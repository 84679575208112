import PageLayout from '@/layouts/PageLayout.vue';
const register = {
  path: '',
  redirect: '/register/index',
  component: PageLayout,
  children: [
    {
      path: 'index',
      name: '注册',
      component: () => import(/* webpackChunkName: "register" */ '../views/login/register.vue'),
      meta: { name: '注册页' },
    },
    {
      //第一步
      path: 'step-1',
      name: '注册账号第一步',
      component: () =>
        import(/* webpackChunkName: "register" */ '../views/login/restep/Step-1.vue'),
      meta: { name: '注册账号第一步' },
    },
    // {
    //   //第二步
    //   path: "step-2",
    //   name: "注册账号第二步",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "register" */ "../views/login/restep/StepTwo.vue"
    //     ),
    //   meta: { name: "注册账号第二步" },
    // },
    {
      //第三步
      path: 'step-3',
      name: '注册账号第三步',
      component: () =>
        import(/* webpackChunkName: "register" */ '../views/login/restep/Step-3.vue'),
      meta: { name: '注册账号第三步' },
    },
  ],
};
export default register;
