import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75bd6be0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_right = _resolveComponent("arrow-right")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['footer-btns flex-center', _ctx.mod])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.codeBtn, (item, index) => {
      return (_openBlock(), _createBlock(_component_el_popover, {
        placement: "top-start",
        width: 200,
        trigger: "hover",
        key: 'code_' + index
      }, {
        reference: _withCtx(() => [
          _createVNode(_component_el_button, {
            round: "",
            size: "large",
            style: {"margin":"0 15px"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(item.label), 1),
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_arrow_right)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1024)
        ]),
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: "app-img",
            src: item.img,
            alt: ""
          }, null, 8, _hoisted_1)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ], 2))
}