export default class Storage {
  /**
   * localstorage设置缓存
   * @param key
   * @param val
   */
  static set<T>(key: string, val: T | T[]): void {
    localStorage.setItem(key, JSON.stringify(val));
  }
  /**
   * localstorage读取缓存
   * @param key
   * @returns
   */
  static get<T>(key: string): T | boolean {
    const value = localStorage.getItem(key);
    if (value && value != 'undefined' && value != 'null') {
      return <T>JSON.parse(value);
    }
    return false;
  }
  /**
   * localstorage删除缓存
   * @param key
   */
  static remove(key: string): void {
    localStorage.removeItem(key);
  }
  /**
   * localstorage清空缓存
   */
  static clear(): void {
    localStorage.clear();
  }

  /**
   * sessionStorage设置缓存
   * @param key
   * @param val
   */
  static sessionSet<T>(key: string, val: T | T[]): void {
    window.sessionStorage.setItem(key, JSON.stringify(val));
  }
  /**
   * sessionStorage读取缓存
   * @param key
   * @returns
   */
  static sessionGet<T>(key: string): T | boolean {
    const value = window.sessionStorage.getItem(key);
    if (value && value != 'undefined' && value != 'null') {
      return <T>JSON.parse(value);
    }
    return false;
  }
  /**
   * sessionStorage删除缓存
   * @param key
   */
  static sessionRemove(key: string): void {
    window.sessionStorage.removeItem(key);
  }
  /**
   * sessionStorage清空缓存
   */
  static sessionClear(): void {
    window.sessionStorage.clear();
  }
}
