
  import { defineComponent } from 'vue';
  import { ArrowDown } from '@element-plus/icons-vue';
  export default defineComponent({
    name: 'LoginHeader',
    components: { ArrowDown },
    setup() {
      return {};
    },
  });
