import { inject, ref } from 'vue';
import User from '@/common/api/service/user';
import * as UserModel from '@/common/api/model/user';

export default function useApiSendSms() {
  const $f: any = inject('$f');
  //验证码时间 默认60秒
  const defaultCodeTime = 60;
  //是否发送验证码
  const isSendCode = ref(false);
  //倒计时显示文本
  const codeTime = ref(0);
  //是否禁用验证码按钮
  const isDisable = ref(true);

  //发送验证码逻辑
  function apiSendSmsFn(params: UserModel.ApiSendSmsModel) {
    if (isSendCode.value) {
      return false;
    }
    User.apiSendSms(params, { useLoading: false }).then(() => {
      $f.message.success('发送成功');
      codeTime.value = defaultCodeTime;
      setInterval(() => {
        if (codeTime.value > 0) {
          codeTime.value -= 1;
        } else {
          isSendCode.value = false;
          isDisable.value = false;
        }
      }, 1000);
      isDisable.value = true;
      isSendCode.value = true;
    });
  }

  return {
    isSendCode,
    codeTime,
    isDisable,
    apiSendSmsFn,
  };
}
