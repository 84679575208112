import { inject } from 'vue';

export default function useComFunc() {
  const $store: any = inject('$store');

  const loginoutFn = async () => {
    await $store.dispatch('apiUserLoginoutFn');
  };

  return {
    loginoutFn,
  };
}
