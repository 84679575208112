import axios from '../axios';
// import * as CartModel from "../model/cart";

export default class Cart {
  /**
   * 获取热销商品
   * @param params
   * @returns
   */
  public static async getCartList(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/carts_list',
    });
  }
  public static async getCartNbList(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/integral_cart',
    });
  }

  /**
   *
   * @param cartIds
   * @param type 1=选中，0=取消选中
   * @returns
   */
  public static async checkCart(cartIds: number[], type: number): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/check_cart',
      data: {
        cart_ids: cartIds.join(','),
        type: type,
      },
    });
  }

  /**
   * 删除购物车
   * @param cartIds
   * @returns
   */
  public static async delCart(cartIds: number[]): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/del_cart',
      data: { cart_ids: cartIds.join(',') },
    });
  }

  /**
   * 加减购物车数量
   * @param cartIds
   * @returns
   */
  public static async updateCartCount(cartId: number, count: number): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/update_cart',
      data: { cart_id: cartId, cart_count: count },
    });
  }

  /**
   * 获取购物车数量
   * @param params
   * @returns
   */
  public static async apiGetCartCount(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/get_cart_count',
    });
  }
  /**
   * 获取购物车数量
   * @param params
   * @returns
   */
  public static async apiGetCartNbCount(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/get_cart_count?type=1',
    });
  }
}
